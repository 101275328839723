import React from 'react';

import heroStyles from './hero.module.scss';

const Hero = () => {
    return (
        <div className={heroStyles.wrapper}>
            <div className={heroStyles.container}>
                <div className={heroStyles.heroTitle}>
                    <h1>Fullstack Developer/Computer Engineering Student</h1>
                    <p>
                        Passionate about Computer Science and always looking to learn
                        something new.
                    </p>
                </div>
                <div className={heroStyles.svgContainer}>
                    <svg
                        className={heroStyles.heroDesign}
                        viewBox="0 0 820 594"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M268.644 9.16597C297.898 11.0541 320.079 33.0524 344.545 49.2309C370.424 66.3438 404.529 77.0217 415.306 106.15C426.169 135.509 410.753 167.313 398.964 196.311C387.979 223.332 371.902 246.889 350.188 266.331C326.262 287.753 300.729 312.556 268.644 312.994C236.441 313.435 211.549 287.51 185.466 268.584C159.338 249.626 129.589 233.036 117.276 203.163C104.476 172.111 105.124 135.876 117.976 104.846C130.301 75.0893 158.128 56.2001 185.289 38.952C210.83 22.7326 238.467 7.21824 268.644 9.16597Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M459.073 54.8581C537.851 56.2284 594.956 116.954 649.907 173.418C716.688 242.037 818.825 309.501 797.809 402.917C776.777 496.401 655.029 512.296 565.807 547.237C489.291 577.203 412.515 610.047 334.454 584.377C241.744 553.889 141.422 498.096 124.238 402.027C107.466 308.264 193.605 235.19 259.729 166.631C315.369 108.941 378.936 53.4641 459.073 54.8581Z"
                            fill="#A8DADC"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M198.711 329.027C212.595 349.517 223.957 373.761 218.029 397.794C212.19 421.463 189.933 435.592 169.242 448.487C148.837 461.204 126.757 473.665 103.091 469.424C78.6744 465.048 58.6245 447.671 45.8951 426.381C33.5215 405.687 30.0416 380.808 36.1595 357.484C42.055 335.009 58.2899 317.573 77.7625 304.893C97.4689 292.061 120.339 283.052 143.399 287.654C166.873 292.337 185.285 309.212 198.711 329.027Z"
                            fill="#495367"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M635.994 0.10917C670.681 2.19052 697.42 30.7035 723.072 54.1585C744.959 74.17 757.349 100.751 773.367 125.715C790.215 151.972 821.938 173.939 819.907 205.076C817.858 236.484 785.241 256.034 763.388 278.672C745.893 296.794 725.164 309.503 704.795 324.317C681.942 340.939 663.325 364.17 635.994 371.33C605.349 379.358 570.15 382.586 542.689 366.784C515.223 350.979 501.679 318.495 491.82 288.366C483.034 261.515 495.193 232.804 489.776 205.076C482.619 168.44 443.535 136.072 455.137 100.594C466.166 66.8681 511.665 60.5599 542.669 43.3337C573.158 26.3941 601.183 -1.97961 635.994 0.10917Z"
                            fill="#1D3557"
                        />
                    </svg>
                </div>
            </div>
            <div className={heroStyles.arrowsLabel}>Scroll down to see my work</div>
            <div className={heroStyles.arrowsContainer}>
                <div className={heroStyles.aboveArrows}>
                    <span></span>
                    <span></span>
                </div>
                <div className={heroStyles.belowArrows}>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    );
};

export default Hero;
