import React from 'react';

import Hero from './hero';
import Work from './work';
// import Project from './project';
import homeStyles from './home.module.scss';

const Home = () => {
    return (
        <div className={homeStyles.container}>
            <Hero />
            <section id="work_section">
                <Work />
            </section>
            {/* <section id="project_section">
                <Project />
            </section> */}
        </div>
    );
};

export default Home;
