import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Icon } from '@iconify/react';
import desktopIcon from '@iconify/icons-fa-solid/desktop';
import serverIcon from '@iconify/icons-fa-solid/server';
import databaseIcon from '@iconify/icons-fa-solid/database';

import workStyles from './work.module.scss';

const Work = () => {
    const data = useStaticQuery(graphql`
        query {
            main: file(relativePath: { eq: "work-main.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1530, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            ui: file(relativePath: { eq: "work-1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1530, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            features: file(relativePath: { eq: "work-2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1530, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <div className={workStyles.wrapper}>
            <div className={workStyles.container}>
                <div className={workStyles.textContainer} data-sal="slide-right">
                    <p>Work Experience</p>
                    <h1>LiteLeaf</h1>
                    <p>
                        Web application to monitor and control hydroponics through data
                        visualization. A proof of concept for an Internet of
                        Technology(IoT) project. Users are able to set control limits and
                        be alerted when data readings exceed those limits.
                    </p>
                </div>
                <div className={workStyles.imgContainer} data-sal="slide-left">
                    <Img fluid={data.main.childImageSharp.fluid} />
                </div>
            </div>
            <div className={workStyles.container}>
                <div className={workStyles.textContainer} data-sal="slide-left">
                    <p>UI/UX Design</p>
                    <h1>Simple Industrial Design</h1>
                    <p>
                        Simple design with the focus on usability and performance. Basic
                        design principles are applied with consistent themeing and visual
                        clarity.
                    </p>
                </div>
                <div className={workStyles.imgContainer} data-sal="slide-right">
                    <Img fluid={data.ui.childImageSharp.fluid} />
                </div>
            </div>
            <div className={workStyles.container}>
                <div className={workStyles.textContainer} data-sal="slide-left">
                    <p>Features</p>
                    <h1>Authentication & Authorization</h1>
                    <p>
                        Complete account management system that allows admins/managers to
                        control access of other users.
                    </p>
                </div>
                <div className={workStyles.imgContainer} data-sal="slide-right">
                    <Img fluid={data.features.childImageSharp.fluid} />
                </div>
            </div>
            <span className={workStyles.divider}></span>
            <div className={workStyles.cardsContainer} data-sal="slide-up">
                <div className={workStyles.card}>
                    <Icon
                        icon={desktopIcon}
                        style={{ color: '#1d3557', fontSize: '68px' }}
                    />
                    <h2>Frontend development</h2>
                    <p>Reactjs to build the UI along with Bootstrap for styling</p>
                </div>
                <div className={workStyles.card}>
                    <Icon
                        icon={serverIcon}
                        style={{ color: '#1d3557', fontSize: '68px' }}
                    />
                    <h2>Backend development</h2>
                    <p>Nodejs with Express framework deployed using AWS</p>
                </div>
                <div className={workStyles.card}>
                    <Icon
                        icon={databaseIcon}
                        style={{ color: '#1d3557', fontSize: '68px' }}
                    />
                    <h2>Database</h2>
                    <p>MongoDB hosted on Atlas for a NoSQL cloud storage</p>
                </div>
            </div>
        </div>
    );
};

export default Work;
