import React from 'react';

import Layout from '../components/layout';
import Home from '../scenes/Home/Home.js';

const IndexPage = () => (
    <Layout>
        <Home />
    </Layout>
);

export default IndexPage;
